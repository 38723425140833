@import './apply.css';
@import './custom-default.css';
@import './search.css';
@import './card.css';
@import './explore.css';
@import './reppl.css';

@media (min-width: 768px) {
  .md\:width-960 {
    width: 960px;
  }
  .md\:width-1080 {
    width: 1080px;
  }
}

@media (min-width: 1440px) {
}
#main {
  height: 100%;
  min-height: calc(100vh - 70px);
}
.desktop-width {
  width: 1440px;
}
.desktop-max-width {
  max-width: 1440px;
  margin: 0 auto;
}
.w-container {
  max-width: 1272px;
}
.tip {
  width: 14.375rem;
  border: 1px solid #e6e8e9;
}
.tip-fadeIn {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
.tip:after,
.tip:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.tip:after {
  left: -20px;
  border-color: transparent #e6e8e9 transparent transparent;
  border-width: 10px;
  top: 42px;
}

.tip:before {
  left: -19px;
  top: 41px;
  border-color: transparent white transparent transparent;
  border-width: 11px;
  z-index: 10;
}
.license-tip {
  right: -15.375rem;
  top: -2.4rem;
}
.svg-logo-size {
  width: 70% !important;
  height: initial;
}
.button-disable {
  background-color: #9ac9f4;
}
.card-wrap {
  min-height: 385px;
  width: 100%;
}
.hoveredUser {
  background: #c5c5c5;
}
#editor a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}
#studioForm a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}
#signinModal a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}
#studioForm p {
  padding: 12px 0;
}
#studioForm :is(h1, h2, h3, h4, h5, h6):first-child {
  padding: 0 0 8px 0;
}
#studioForm :is(h1, h2, h3, h4, h5, h6) {
  padding: 30px 0 8px 0;
}
#toolTip a {
  text-decoration: none;
}
.quotation {
  top: -16px;
  line-height: 72px;
}
.Montserrat {
  font-family: Montserrat;
}
.tab-link .link {
  margin-right: 3rem;
}
.tab-link .link:last-child {
  margin-right: 0;
}
.explore-link .link-active,
.tab-link .link-active,
.reppl-nav .link-active {
  border-bottom: 3px solid;
}

.marquee:hover .marquee-child {
  animation-play-state: paused;
}

.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.fade-default {
  transition: opacity 400ms ease-in-out;
  opacity: 0;
}
.fade-entering,
.fade-entered {
  opacity: 1;
}
.fade-exiting,
.fade-exited {
  opacity: 0;
}
.comma::after {
  content: ',';
}
.comma:last-child::after {
  content: '';
}
.tab-bar {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.accordion {
  transition: max-height 0.4s ease-in-out;
}
.accordion-max-hgight {
  max-height: 1000px;
}
.accordion .list-layer-content::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #9b9fa4;
  content: '';
  position: absolute;
  right: 9px;
  top: 14px;
}
.accordion .list-layer-content:hover .sub-list-layer {
  display: block;
  left: 216px;
  width: fit-content;
  min-width: 216px;
}
.sub-list-layer .reset {
  margin-right: 1rem;
}
.list-layer li {
  padding-left: 0.563rem;
  padding-top: 0.563rem;
  padding-bottom: 0.563rem;
  min-height: 2.188rem;
}
.list-layer li:hover {
  background: rgba(0, 0, 0, 0.04);
}
.delist:hover {
  background: unset !important;
}
.layer-icon-hover:hover svg {
  display: inline;
}
.line-clamp,
.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4,
.line-clamp-6,
.line-clamp-10 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-1 {
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  -webkit-line-clamp: 2;
}
.line-clamp-3 {
  -webkit-line-clamp: 3;
}
.line-clamp-4 {
  -webkit-line-clamp: 4;
}
.line-clamp-5 {
  -webkit-line-clamp: 5;
  min-height: 100px;
}
.line-clamp-6 {
  -webkit-line-clamp: 6;
}
.line-clamp-10 {
  -webkit-line-clamp: 10;
}
.input-placeholder::-webkit-input-placeholder {
  text-align: center;
}

.input-placeholder:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.input-placeholder::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.input-placeholder:-ms-input-placeholder {
  text-align: center;
}
/* desktop */
@media (min-width: 768px) {
  .md\:line-clamp-3 {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .md\:block-import {
    display: block !important;
  }
}
footer {
  z-index: 500;
}
.app-popup {
  z-index: 1000;
}
.app-popup .container {
  top: 10rem;
}
.filter-popup {
  z-index: 1000;
}
.filter-popup-layer-2 .list-layer {
  margin-top: 0;
}
.filter-popup-layer-2 {
  z-index: 1002;
}
.filter-popup-layer-list .layer-icon-hover {
  padding: 1.5rem;
  border-top: 1px solid #d3d6d8;
}
.filter-popup-layer-list .list-layer > .cursor-pointer {
  padding: 1.5rem;
}
.filter-popup-layer-list .list-layer .reset {
  color: #9b9fa4;
}
.arrow-up {
  position: absolute;
  width: 0;
  height: 0;
  top: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid currentColor;
}
.negative-left {
  left: -2rem;
}
.wave-bg {
  background-image: url('/images/assets/wave-white.svg');
  background-repeat: repeat;
}
.collapse.image:hover {
  width: 105px;
  transition: width 0.7s;
}
.collapse.hashtag:hover {
  width: 120px;
  transition: width 0.7s;
}
.collapse.discussion:hover {
  width: 140px;
  transition: width 0.7s;
}
.collapse.poll:hover {
  width: 90px;
  transition: width 0.7s;
}
.collapse:hover div {
  opacity: 1;
  transition: opacity 0.5s;
}
.collapse div {
  opacity: 0;
  transition: opacity 0.5s;
}
.collapse .icon {
  opacity: 1;
  transition: opacity 0.5s;
}
.collapse:hover .icon {
  opacity: 1;
  transition: opacity 0.5s;
}
.collapse:hover .svgIcon {
  color: #4f5356;
}
.collapse {
  width: 42px;
  overflow: hidden;
  transition: width 0.7s;
}
.item-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.item-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slider-container-transition {
  transition: transform 300ms ease-in-out;
}
.skeleton-circle {
  @apply rounded-full bg-gray-300 opacity-30;
}
.skeleton-line {
  @apply rounded-sm bg-gray-300 opacity-30;
}
.skeleton-circle-white {
  @apply rounded-full bg-white opacity-30;
}
.skeleton-line-white {
  @apply rounded-sm bg-white opacity-30;
}
.section-empty {
  background-image: url('/images/assets/wave-gray.svg');
  background-repeat: repeat;
  text-align: center;
}
.section-empty-wrap {
  width: 100%;
}
.card-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}
.inline-code {
  background: rgba(250, 239, 240, 0.78);
  color: #b44437;
  padding: 3px 4px;
  border-radius: 5px;
  margin: 0 1px;
  font-family: inherit;
  font-size: 0.86em;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.full-banner {
  margin-top: -3.75rem;
}
.w-fit {
  width: 'fit-content';
}
.bg-blend-mode {
  background-blend-mode: multiply, normal;
}
.h-900 {
  height: 900px;
}
.nav-panel-top {
  top: 60px;
  z-index: 1000;
}
.div-offest {
  margin-top: -60px;
}
.nav .menu-list {
  padding: 24px;
}
.policy p,
.policy h5,
.policy ul {
  margin-bottom: 12px;
}
.policy ul {
  list-style: disc;
  margin-left: 20px;
}
.policy ul li,
.policy p {
  line-height: 1.8;
}
.policy .list-lower-latin {
  list-style-type: lower-latin;
}
.policy .list-circle {
  list-style-type: circle;
}
.parallelogram {
  width: 84px;
  height: 5px;
  background: #242526;
  transform: rotate(90deg) skew(145deg);
}
.bg-seagreen-100 {
  background-color: #eaf9f7;
}
.bg-outrageousOrange-100 {
  background-color: #fff0eb;
}
.select-categories {
  border: 1px solid #ff6633;
  border-radius: 5px;
}
.fontWeight-500 {
  font-weight: 500 !important;
}
.overflow-auto::-webkit-scrollbar {
  display: none
}
.overflow-auto {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.rounded-3xl {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
#nav {
  z-index: 99 !important;
}
.card-300 {
  width: 300px !important;
  margin: 0 12px !important;
}
b {
  @apply font-bold text-gray-900;
}
