.search-panel-wrap {
  top: 3.75rem;
}
.filter-wrap {
  width: 216px;
}
.search-width {
  width: 1272px;
}
.search-right-width {
  width: 948px;
}
.search-result.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
@media (min-width: 1024px) {
  .global-search {
    width: 948px;
  }
}
